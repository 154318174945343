<template>
  <div v-if="show">
    <!-- 加購 -->
    <div v-if="showSection(pluses)">
      <div class="cart_tr tr-mutiple add_sale">
        <div class="cart_td td-mutiple_title" @click="showPlus = !showPlus">
          <p class="mutiple_title txt-white">
            <span>加購</span>
          </p>
        </div>
        <div class="cart_td td-add_inner"  :class="{ active: showPlus }">
          <div
            class="cart_tr"
            v-for="(plus, i) in pluses"
            :key="`pluses_${i}_${itemKey}_${plus && plus.id}`"
            v-show="showSelected(plus)"
          >
            <div class="cart_td td-add-product">
              <input type="checkbox"
                v-model="plus.selected"
                :disabled="$route.name !== 'CartInfo' || !plus.status"
                @input="($event) => selected(plus.selected, i, getType(2))"
                :name="`plus_${i}_${itemKey}`" :id="`plus_${i}_${itemKey}`"
              >
              <label
                :for="`plus_${i}_${itemKey}`"
                :style="`${plus.status ? '' : 'cursor: not-allowed'}`"
              >
                <span :style="`
                  ${plus.status
                    ? routeName === 'CartDetail'
                      ? 'opacity: 0' : '' : 'cursor: not-allowed'}`"
                ></span>
                <div class="add_icon" v-if="plus.cover">
                  <figure
                    :style="`background-image: url(${plus.cover});`"></figure>
                </div>
                <div class="add_name">
                  <h6>{{plus.product_name}}
                    <span v-if="!plus.status" class="txt-red">(無庫存)</span>
                    <span
                      v-if="showPurchaseLimitError(plus)"
                      class="txt-red"
                    >(超過加購限額)</span>
                  </h6>
                  <small v-if="plus.info" class="txt-greenlake">{{ plus.info }}</small>
                </div>
              </label>
            </div>
            <div
              class="cart_td td-time center rwd-wifi" data-rwd="時間"
              v-if="plus.caculate_way === 1">
              <p>{{item.days}} 天</p>
            </div>
            <!-- 1 加購金額一致 2 否 -->
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-if="plus.same_amount === 1">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    {{ plus.unit || '個' }}
                  </span>
                </p>
              </div>
            </div>
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-else-if="plus.same_amount === 2">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    {{ plus.unit || '個' }}
                  </span>
                </p>
              </div>
            </div>
            <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                <div class="cart_number" data-max="10">
                  <div
                    v-if="$route.name === 'CartInfo'"
                    class="minus" @click="minusItem(i, getType(2))">-</div>
                  <input id="cart_number001" class="count p" type="text" :value="plus.qty" readonly>
                  <div
                    v-if="$route.name === 'CartInfo'"
                    :style="`${checkingStock ? 'cursor: not-allowed' : null}`"
                    class="plus" @click="plusItem(i, getType(2), plus)">+</div>
                </div>
              </div>
            <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
              <div>
                <p>
                  <vue-numeric
                    read-only currency="$" separator="," :value="computedItemTotal(plus)">
                  </vue-numeric>
                </p>
              </div>
            </div>
            <div class="cart_td td-del"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 附加服務 -->
    <div
      v-if="showSection(addServices)"
      @click="showAddServices = !showAddServices"
    >
      <div class="cart_tr tr-mutiple add_sale">
        <div class="cart_td td-mutiple_title">
          <p class="mutiple_title txt-white">
            <span>附加服務</span>
          </p>
        </div>
        <div class="cart_td td-add_inner" :class="{ active: showAddServices }">
          <div
            class="cart_tr"
            v-for="(plus, i) in addServices"
            :key="`pluses_${i}_${plus && plus.id}`"
            v-show="showSelected(plus)"
          >
            <div class="cart_td td-add-product">
              <input type="checkbox"
                v-model="plus.selected"
                :disabled="$route.name !== 'CartInfo' || !plus.status"
                @input="selected(plus.selected, i, getType(3))"
                :name="`addServices_${i}${itemKey}`" :id="`addServices_${i}${itemKey}`">
              <label
                :for="`addServices_${i}${itemKey}`"
                :style="`${plus.status ? '' : 'cursor: not-allowed'}`"
              >
                <span :style="`
                  ${plus.status
                    ? routeName === 'CartDetail'
                      ? 'opacity: 0' : '' : 'cursor: not-allowed'}`"
                ></span>
                <div class="add_icon" v-if="plus.cover">
                  <figure
                    :style="`background-image: url(${plus.cover});`"></figure>
                </div>
                <div class="add_name">
                  <h6>{{plus.product_name}}
                    <span v-if="!plus.status" class="txt-red">(無庫存)</span>
                    <span
                      v-if="showPurchaseLimitError(plus)"
                      class="txt-red"
                    >(超過加購限額)</span>
                  </h6>
                  <small v-if="plus.info" class="txt-greenlake">{{ plus.info }}</small>
                </div>
              </label>
            </div>
            <div
              class="cart_td td-time center rwd-wifi" data-rwd="時間"
              v-if="plus.caculate_way === 1">
              <p>{{item.days}} 天</p>
            </div>
            <!-- 1 加購金額一致 2 否 -->
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-if="plus.same_amount === 1">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    {{ plus.unit || '個' }}
                  </span>
                </p>
              </div>
            </div>
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-else-if="plus.same_amount === 2">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    {{ plus.unit || '個' }}
                  </span>
                </p>
              </div>
            </div>
            <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                <div class="cart_number" data-max="10">
                  <div
                    v-if="$route.name === 'CartInfo'"
                    class="minus" @click="minusItem(i, getType(3))">-</div>
                  <input
                  id="cart_number001" class="count p" type="text" :value="plus.qty" readonly>
                  <div
                    v-if="$route.name === 'CartInfo'"
                    :style="`${checkingStock ? 'cursor: not-allowed' : null}`"
                    class="plus" @click="plusItem(i, getType(3), plus)">+</div>
                </div>
              </div>
            <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
              <div>
                <p>
                  <vue-numeric
                    read-only currency="$" separator="," :value="computedItemTotal(plus)">
                  </vue-numeric>
                </p>
              </div>
            </div>
            <div class="cart_td td-del"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 贈品 -->
    <div v-if="frees.length > 0"  @click="showFree = !showFree">
      <div
        v-for="(free, i) in frees"
        :key="`free_${i}_${free && free.id}`"
        class="cart_tr tr-mutiple add_gift"
        v-show="showSelected(free)"
      >
        <div class="cart_td td-mutiple_title">
            <p class="mutiple_title txt-white">
                <span>贈品</span>
            </p>
        </div>
        <div class="cart_td td-add_inner"  :class="{ active: showFree }">
            <div class="cart_tr">
                <div class="cart_td td-add-product">
                  <input type="checkbox"
                    v-model="free.selected"
                    :disabled="$route.name !== 'CartInfo' || !free.status"
                    @input="selected(free.selected, i, getType(1), free.id)"
                    :name="`frees_${i}${itemKey}`" :id="`frees_${i}${itemKey}`">
                  <label
                    :for="`frees_${i}${itemKey}`"
                    :style="`${free.status ? '' : 'cursor: not-allowed'}`"
                  >
                    <span :style="`
                      ${free.status
                        ? routeName === 'CartDetail'
                          ? 'opacity: 0' : '' : 'cursor: not-allowed'}`"
                    ></span>
                    <div class="add_icon"  v-if="free.cover">
                      <figure
                        :style="`background-image: url(${free.cover});`">
                      </figure>
                    </div>
                    <div class="add_name">
                      <h6>{{free.product_name}}
                        <span v-if="!free.status" class="txt-red">(無庫存)</span></h6>
                      <small v-if="free.info" class="txt-greenlake">{{ free.info }}</small>
                    </div>
                  </label>
                </div>
                <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                    <p>{{free.free_limit}}</p>
                </div>
                <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
                    <div>
                        <p>Free</p>
                    </div>
                </div>
                <div class="cart_td td-del"></div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { compact } from '@/lib/lodash';
import requestApi from '@/lib/http/index';

export default {
  name: 'CartPlus',
  props: {
    qtyChange: Boolean,
    item: {},
    itemKey: {
      type: String,
      default: uuidv4(),
    },
  },
  data() {
    return {
      checkingStock: false,
      show: false,
      showPlus: false,
      showAddServices: false,
      showFree: false,
      pluses: [],
      frees: [],
      addServices: [],
    };
  },
  methods: {
    ...mapActions('moduleCart', {
      actionHandleCartLoading: 'handleCartLoading',
    }),
    getType(type) {
      let plusType;
      if (type === 2) {
        plusType = 'pluses';
      } else if (type === 3) {
        plusType = 'addServices';
      } else {
        plusType = 'frees';
      }
      return plusType;
    },
    // FIXME:
    async selected(selectedStatus, index, plusType, plusEventId) {
      const changeStatus = !selectedStatus;
      if (changeStatus) {
        let total;
        let qty;
        if (plusType === 'frees') {
          // 取得跨主商品的贈品數量
          const targetQty = (await this.getPlusesQty() || [])
            .find((item) => item.plus_event_id === plusEventId)?.qty || 0;
          const { status: stockStatus, message } = await this.checkStock({
            abroad_start: this.item.abroad_start,
            abroad_end: this.item.abroad_end,
            product_id: 'null',
            plus_event_id: plusEventId,
            qty: targetQty + this[plusType][index].free_limit,
            code: this.item.code || null,
          });
          if (!stockStatus) {
            this[plusType][index].selected = false;
            this.$customSWAL({
              icon: 'error',
              title: '錯誤',
              text: message,
              confirmButtonText: '確定',
            });
            return;
          }
          total = 0;
          qty = this[plusType][index].free_limit;
        } else {
          total = this.computedItemTotal(this[plusType][index]);
          qty = this[plusType][index].qty;
        }
        const formData = {
          member_id: this.user.id,
          cart_id: this.item.id,
          plus_event_id: this[plusType][index].id,
          qty,
          total,
          days: this.item.days ?? 0,
        };
        if (plusType === 'frees') {
          formData.price = 0;
        } else {
          formData.price = this[plusType][index].plus_range;
        }
        const { status, message } = await requestApi('cart.addPlusItem', formData);
        if (!status) {
          this[plusType][index].selected = false;
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: message,
            confirmButtonText: '確定',
          });
        }
      } else {
        const formData = {
          member_id: this.user.id,
          cart_id: this.item.id,
          plus_event_id: plusEventId || this[plusType][index].id,
        };
        this[plusType][index].qty = 1;
        const { status, message } = await requestApi('cart.minusPlusItem', formData);
        if (!status) {
          this[plusType][index].selected = true;
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: message,
            confirmButtonText: '確定',
          });
        }
      }
    },
    resetPlus() {
      [...this.pluses, ...this.addServices, ...this.frees].forEach((item) => {
        if (!item.selected && !item.qty) return;
        this.selected(
          true,
          this[{ 1: 'frees', 2: 'pluses', 3: 'addServices' }[item.event_type]].findIndex((p) => p.id === item.id),
          { 1: 'frees', 2: 'pluses', 3: 'addServices' }[item.event_type],
          item.id,
        );
      });
    },
    computedItemTotal(product) {
      let total = 0;
      if (product.same_amount === 1) {
        total = parseInt(product.plus_range, 10) * product.qty;
      } else {
        total = parseInt(product.plus_range, 10) * product.qty;
      }
      if (product.caculate_way === 1) {
        const days = this.item.days ?? 1;
        total *= days;
      }
      return total;
    },
    minusItem(v, plusType) {
      if (this[plusType][v].qty > 0) {
        this[plusType][v].qty -= 1;
      }
      if (this[plusType][v].selected) {
        this.selected(false, v, plusType);
      }
    },
    async checkStock(product, data) {
      this.checkingStock = true;
      const { status, message } = await requestApi('cart.checkEnoughStock', {
        abroad_start: product.abroad_start,
        abroad_end: product.abroad_end,
        product_id: 'null',
        plus_event_id: product.plus_event_id,
        qty: product.qty,
        code: product.code || this.$route.query.code || null,
      });

      this.checkingStock = false;
      return { status, message, data };
    },
    async plusItem(v, plusType, plus = {}) {
      if (this.checkingStock) return;
      const limit = this[plusType][v].purchase_limit
      || this.item.qty;
      const plusCount = this[plusType][v].qty + 1;
      const { status: plusStockStatus } = await this.checkStock({
        abroad_start: this.item.abroad_start,
        abroad_end: this.item.abroad_end,
        product_id: 'null',
        plus_event_id: plus.id,
        qty: plus.qty + 1,
        code: this.item.code || null,
      });

      if (!plusStockStatus) {
        this.$customSWAL({
          icon: 'info',
          title: '此加購品庫存不足',
        });
      } else if (plusCount <= limit) {
        this[plusType][v].qty = plusCount;
      } else {
        this.$customSWAL({
          icon: 'info',
          title: '超過此加購品加購數量限制',
        });
      }
      if (this[plusType][v].selected) {
        this.selected(false, v, plusType);
      }
    },

    async methodCheckPlusesStock() {
      // 取得跨主商品 同區間同加購品總數資料
      const plusDetail = await this.getPlusesQty();
      if (plusDetail.length) {
        const plusRequestList = plusDetail.map((item) => this.checkStock(item, item));
        return (await Promise.all(plusRequestList))
          .map(({ status, data }) => ({ ...data, status }))
          .reduce((res, item) => {
            // eslint-disable-next-line no-param-reassign
            res[item.plus_event_id] = item.status;
            return res;
          }, {});
      }
      return {};
    },

    /* 取得商品之加購品/贈品/附加品 */
    async getProductPlusEvents() {
      this.actionHandleCartLoading(1);

      const plusStockStatusObj = await this.methodCheckPlusesStock();

      const data = this.item;
      // 曾經選擇過的加購品
      const { data: userPlusItem = [] } = await requestApi('cart.getPlusItem', {
        member_id: this.user.id,
        cart_id: this.item.id,
      });

      const selectedItem = (userPlusItem || []).map(({ plus_event_id: id }) => id);

      // 目前主商品加購品清單
      const result = await requestApi('promotion.getProductPlusEvents', {
        product_id: data.product_id,
        qty: data.qty,
        sub_total: data.beforeDiscountTotal,
        days: data.days,
        abroad_start: data.abroad_start,
        abroad_end: data.abroad_end,
        code: data.code,
      });
      if (result.status) {
        this.show = true;
        const { frees = [], plus = [], service = [] } = result.data
          .map((item) => {
            const { qty = 0 } = (userPlusItem || [])
              .find(({ plus_event_id: pid }) => pid === item.id) || {};
            return {
              ...item,
              selected: plusStockStatusObj[item.id] ? selectedItem.includes(item.id) : false,
              qty: plusStockStatusObj[item.id] ? qty : 1,
              status: plusStockStatusObj[item.id] ? plusStockStatusObj[item.id] : true,
            };
          }).reduce((res, item) => {
            const type = { 1: 'frees', 2: 'plus', 3: 'service' }[item.event_type];
            res[type].push(item);
            return res;
          }, { frees: [], plus: [], service: [] });

        this.pluses = plus;
        this.frees = frees;
        this.addServices = service;
        [...plus, ...frees, ...service].forEach((item) => {
          if (item.status) return;
          this.selected(true, null, { 1: 'frees', 2: 'pluses', 3: 'addServices' }[item.event_type], item.id);
        });
        // getPlusItem.data.forEach((item) => {
        //   /* 已選贈品 */
        //   const freeIndex = this.frees.findIndex(
        //     (plusItem) => plusItem.id === item.plus_event_id,
        //   );
        //   if (freeIndex >= 0) {
        //     this.frees[freeIndex].qty = item.qty;
        //     this.frees[freeIndex].selected = true;
        //   }
        //   /* 已選加購品 */
        //   const index = this.pluses.findIndex((plusItem) => plusItem.id === item.plus_event_id);
        //   if (index >= 0) {
        //     this.pluses[index].qty = item.qty;
        //     this.pluses[index].selected = true;
        //   }
        //   /* 已選附加商品 */
        //   const addServiceIndex = this.addServices.findIndex(
        //     (plusItem) => plusItem.id === item.plus_event_id,
        //   );
        //   if (addServiceIndex >= 0) {
        //     this.addServices[addServiceIndex].qty = item.qty;
        //     this.addServices[addServiceIndex].selected = true;
        //   }
        // });
      } else {
        this.show = false;
      }
      this.actionHandleCartLoading(-1);
      /* 資料取得完成,關閉主商品偵測數量變化值 */
      this.$emit('updateQtyChangeValue');
    },
    // FIXME:
    async getPlusesQty(lists = this.orderDetail) {
      /* [主商品]以各cart_id為key, value為期間 */
      const durationObj = lists.reduce((res, item) => {
        // eslint-disable-next-line no-param-reassign
        res[item.id] = {
          abroad_start: item.abroad_start,
          abroad_end: item.abroad_end,
        };
        return res;
      }, {});

      /* 取得使用者已選加購內容 */
      const requestList = lists.map((item) => requestApi('cart.getPlusItem', {
        member_id: this.user.id,
        cart_id: item.id,
      }));
      const resultList = (await Promise.all(requestList)).map(({ data }) => data);

      /* 若使用者無選任何加購內容則回空陣列 */
      if (!compact(resultList).length) return [];

      /* 回傳跨主商品 同區間同加購品的總數 */
      const kk = resultList
        .reduce((prev, curr) => prev.concat(curr), []);
      const ee = kk.map((item) => ({
        // 把 abroad_start abroad_end 加進來
        ...durationObj[item?.cart_id],
        plus_event_id: item?.plus_event_id,
        qty: item?.qty,
        product_id: 'null',
      }))
        .reduce((res, item) => {
          const targetIdx = res.findIndex((i) => i.abroad_start === item.abroad_start
            && i.abroad_end === item.abroad_end
            && i.plus_event_id === item.plus_event_id);
          if (targetIdx !== -1) {
            // eslint-disable-next-line no-param-reassign
            res[targetIdx].qty += item.qty;
          } else {
            res.push(item);
          }
          return res;
        }, []);
      return ee;
    },
    showPurchaseLimitError(plus) {
      // eslint-disable-next-line camelcase
      const { depanden_effect_products, purchase_limit, qty } = plus;
      if (parseInt(depanden_effect_products, 10) === 0) {
        // eslint-disable-next-line camelcase
        return qty > purchase_limit;
      }
      return false;
    },
    checkPurchaseLimit() {
      const result = [];
      this.pluses.reduce((acc, cur) => {
        // eslint-disable-next-line no-nested-ternary
        const limitStatus = parseInt(cur.depanden_effect_products, 10) === 0
          ? cur.purchase_limit
            ? cur.qty <= cur.purchase_limit : true : true;
        result.push(limitStatus);
        return acc;
      }, result);
      this.addServices.reduce((acc, cur) => {
        // eslint-disable-next-line no-nested-ternary
        const limitStatus = parseInt(cur.depanden_effect_products, 10) === 0
          ? cur.purchase_limit
            ? cur.qty <= cur.purchase_limit : true : true;
        result.push(limitStatus);
        return acc;
      }, result);
      return result;
    },
    showSection(section) {
      if (this.routeName === 'CartDetail') {
        return section.length > 0 && (section.findIndex((item) => item.selected) !== -1);
      }
      return section.length > 0;
    },
    /** 購物車明細頁，沒有被選擇的加購、優惠等，不顯示 */
    showSelected(item) {
      const { selected } = item;
      if (this.routeName === 'CartDetail') {
        return !!selected;
      }
      return true;
    },
  },
  watch: {
    computedPlusPrice: {
      handler(val) {
        this.$emit('plusTotalPrice', val);
      },
      immediate: true,
    },
    /* 主商品偵測數量變化值 */
    qtyChange(val, oldVal) {
      if (val !== oldVal && val) {
        this.getProductPlusEvents();
      }
    },
  },
  computed: {
    ...mapState([
      'user',
      'orderDetail',
    ]),
    computedPlusPrice() {
      let total = 0;
      // const selectedItem = this.pluses.filter(({ selected }) => selected);
      this.pluses.forEach((item) => {
        if (item.selected) {
          const itemTotal = this.computedItemTotal(item);
          total += itemTotal;
        }
      });
      this.addServices.forEach((item) => {
        if (item.selected) {
          const itemTotal = this.computedItemTotal(item);
          total += itemTotal;
        }
      });
      return total;
    },
    routeName() {
      return this.$route.name;
    },
  },
  async mounted() {
    if (this.item) {
      await this.getProductPlusEvents();

      const setPlusHasWifi = (plusArr) => {
        const plusHasWifi = plusArr
          .filter((item) => item.selected)
          .find((item) => item.category_name === 'WIFI分享器');
        this.$store.commit('setPlusHasWifi', !!plusHasWifi);
      };
      setPlusHasWifi([...this.pluses, ...this.frees, ...this.addServices]);

      // this.pluses.forEach((item, i) => {
      //   if (window.location.pathname === '/Cart/Info' && !item.selected) {
      //     // 若沒有選中，則呼叫selected
      //     this.selected(item.selected, i, this.getType(2));
      //     this.pluses[i].selected = true;
      //   }
      // });
    }
  },
};
</script>
